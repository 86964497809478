
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/a/[campaign_slug]",
      function () {
        return require("private-next-pages/a/[campaign_slug]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/a/[campaign_slug]"])
      });
    }
  